<template>
  <div class="banner d-flex align-items-center">
      <div class="container">
          <div class="col-12">
              <div class="d-flex align-items-center" v-if="player!==null">
                <span class="player-team">
                  <img :src="`https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${player.team.team_key}.png`" alt="">
                </span>
                <h2 class="player-name text-white">{{player.name}}</h2>
                <div class="ms-auto">
                  <a
                    class="btn btn-light"
                    role="button"
                    target="_blank"
                    :href="`https://xpand2.sportsforecaster.com/api/forecasts/hockey/player-schedules/${$route.params.player}?season-id=2021`"
                    ><img :src="require('../assets/json-file.svg')" width="25" />
                  </a>
                </div>
              </div>
          </div>
      </div>
  </div>
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="table-responsive">
                  <table class="table my-4" id="playerForecast">
                      <thead>
                          <tr>
                              <th scope="col">Date</th>
                              <th scope="col">Time</th>
                              <th scope="col">Opp</th>
                              <th scope="col">
                                  Forecasted Goals
                                  <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
                              </th>
                              <th scope="col">
                                  Forecasted Assists
                                  <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
                              </th>
                              <th scope="col">
                                  Forecasted Points
                                  <i v-if="currentSortDir == 'asc'" class="bi bi-caret-down-fill bi-color"></i>
                              </th>
                              <th scope="col">Boost</th>
                              <th scope="col">Forecasted Game Score</th>
                          </tr>
                      </thead>
                      <tbody v-if="playerForecast!==null">
                          <tr v-for="match in futureGames" :key="match.schedule.id">
                              <td align="left">{{match.schedule.date}}</td>
                              <td>{{match.schedule.time}}</td>
                              <td v-html="getOppValue(match)"></td>
                              <td>{{Number(match.forecasts[0]['guessing_score']).toFixed(2)}}</td>
                              <td>{{player.extra_data ? getForecastedAssistValue(match).toFixed(2) : '-'}}</td>
                              <!-- <td>{{player.extra_data ? player.extra_data.predicted_assists_percent : '-'}}</td> -->
                              <td>{{(Number(match.forecasts[0]['guessing_score']) + getForecastedAssistValue(match)).toFixed(2)}}</td>
                              <td>
                                  <a
                                  class="link-primary text-decoration-none"
                                  role="button"
                                  @click="showModal(match)"
                                  >
                                    <!-- <span> {{ match['forecast']['guessing-score']['boost']>0 ? '+'+match['forecast']['guessing-score']['boost'] : match['forecast']['guessing-score']['boost']}}% </span> -->
                                    <span> {{ match.forecasts[0].boost>0 ? '+'+match.forecasts[0].boost.toFixed(1) : match.forecasts[0].boost.toFixed(1)}}% </span>
                                  </a>
                              </td>
                              <td><router-link class="text-decoration-none" v-html="getForecastedGameScoreValue(match)" :to="`/${match.schedule.event_key}/details`"></router-link></td>
                          </tr>
                      </tbody>
                      <tfoot v-if="playerForecast!==null">
                        <tr class="text-center">
                          <!-- <td></td> -->
                          <td colspan="3" class="text-start ps-xl-4">Forecast for remaining {{futureGames.length}} games</td>
                          <td>{{ getTotalForecastedGoals().toFixed(2)}}</td>
                          <td>{{ getTotalForecastedAssist().toFixed(2)}}</td>
                          <td>{{ getTotalForecastedPoints().toFixed(2)}}</td>
                          <td>-</td>
                          <td>({{ (getTotalForecastedGoals() / futureGames.length).toFixed(2) }} G/GP)</td>
                        </tr>
                        <tr class="text-center" v-if="player.extra_data">
                          <!-- <td></td> -->
                          <td colspan="3" class="text-start ps-xl-4">Stats for prior {{player.extra_data.actual_games_played}} games</td>
                          <!-- <td>{{ futureGames[0]['forecast']['guessings'][0]['intermediate-values']['goals-scored'] }}</td> -->
                          <td>{{ Number(futureGames[0].forecasts[1].intermediate_values.goals_scored).toFixed(2) }}</td>
                          <!-- <td>{{ futureGames[0]['forecast']['guessings'][0]['intermediate-values']['actual-assists'] }}</td> -->
                          <td>{{ Number(player.extra_data.actual_assists).toFixed(2) }}</td>
                          <td>
                            {{
                              (Number(futureGames[0].forecasts[1].intermediate_values.goals_scored)
                              + Number(player.extra_data.actual_assists)).toFixed(2)
                            }}
                          </td>
                          <td>-</td>
                          <td>({{ (Number(futureGames[0].forecasts[1].intermediate_values.goals_scored) / Number(player.extra_data.actual_games_played)).toFixed(2) }} G/GP)</td>
                        </tr>
                        <tr class="text-center">
                          <td colspan="3" class="text-start ps-xl-4">Forecast for entire season</td>
                          <td>{{ getEntireSeasonGoals() }}</td>
                          <td>{{ getEntireSeasonAssists() }}</td>
                          <td>
                            {{ (Number(getEntireSeasonGoals()) + Number(getEntireSeasonAssists())).toFixed(2)}}
                          </td>
                          <td>-</td>
                          <td>({{ (getEntireSeasonGoals() / (Number(player.extra_data.actual_games_played) + futureGames.length)).toFixed(2) }} G/GP)</td>
                        </tr>
                      </tfoot>
                  </table>
              </div>
          </div>
      </div>
  </div>
  <!-- Modals -->
  <PlayerModal
    v-if="isModalVisible"
    :player="player"
    :awayTeam="awayTeam"
    :homeTeam="homeTeam"
    @close="closeModal()"
  ></PlayerModal>
</template>
<script>
import axios from "axios";
// import _ from "lodash";
import { mapGetters } from "vuex";
import PlayerModal from "../components/PlayerModal.vue"

export default {
  name: "GameForecast",
  title: "Game Forecast Prototype",
  components: {
    PlayerModal
  },

  data() {
    return {
      isLoading: false,
      // timezone: "ET",
      isModalVisible: false,
      playerForecast: null,
      awayTeam: null,
      homeTeam: null,
      player: null
    };
  },

  computed: {
    ...mapGetters(["startDate", "endDate"]),
    futureGames(){
      return this.playerForecast.filter(el => Date.parse(`${el.schedule.date}T${el.schedule.time}`) > Date.parse(new Date().toString()))
    },
    priorGames(){
      return this.playerForecast.filter(el => Date.parse(`${el.schedule.date}T${el.schedule.time}`) < Date.parse(new Date().toString()))
    }
  },

  methods: {
    getPlayerForecast() {
        axios.get(
            // `https://staging.formula.forecaster.ca/api/player-schedules/${this.$route.params.player}?season-id=2021`
            `https://xpand2.sportsforecaster.com/api/forecasts/hockey/player-schedules/${this.$route.params.player}`
            )
            .then((r) => {
              console.log(r.data)
                this.playerForecast = r.data.data
                this.player = r.data.player
                // console.log(this.playerForecast)
            }).finally(() => {
                this.isLoading = false;
            });
    },

    getPlayerTeam(match){
      const teamId = this.player.team.id
      if(match.schedule.away_team.id == teamId) return 'away_team'
      if(match.schedule.home_team.id == teamId) return 'home_team'
    },

    getOppValue(match){
      const teamId = this.player.team.id
      if(match.schedule.away_team.id === teamId) return `@ <img width="50px" src="https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${match.schedule.home_team.team_key}.png"></img>${match.schedule.home_team.abbreviation}`
      if(match.schedule.home_team.id === teamId)
      return `vs <img width="50px" src="https://cdn.xmlteam.com/gamesv5/logos/l.nhl.com/official/${match.schedule.away_team.team_key}.png"></img>${match.schedule.away_team.abbreviation}`
    },

    getForecastedAssistValue(match){
      // const predicted_assist_season_percent = match['forecast']['guessings'][0]['intermediate-values']['predicted-assists-season-percent'] * 1
      const predicted_assist_season_percent = this.player.extra_data.predicted_assists_percent * 1
      const teamTotal = this.getPlayerTeam(match)=== 'home_team' ? Number(match.home_guessing_score) : Number(match.away_guessing_score)
      return Number((predicted_assist_season_percent/100) * teamTotal * 1.7)
    },

    getForecastedGameScoreValue(match){
      const homeTeamScore = Number(match.home_guessing_score)
      const homeTeamAbb = match.schedule.home_team.abbreviation
      const awayTeamScore = Number(match.away_guessing_score)
      const awayTeamAbb = match.schedule.away_team.abbreviation
      // // return match
      const winLabel = `<span class='text-success'>W</span>`
      const loseLabel = `<span class='text-danger'>L</span>`
      return `${awayTeamAbb} ${awayTeamScore.toFixed(2)} @ ${homeTeamAbb} ${homeTeamScore.toFixed(2)} - ${homeTeamScore === awayTeamScore ? '' : homeTeamScore < awayTeamScore ? winLabel : loseLabel}`
    },

    getTotalForecastedGoals(events = this.futureGames){
      let total = 0
      events.forEach(element => {
        total = total + Number(element.forecasts[0].guessing_score)
      });
      return total
    },

    getTotalForecastedAssist(events = this.futureGames){
      let total = 0
      events.forEach(element => {
        total += this.getForecastedAssistValue(element)
      });
      return total
    },

    getTotalForecastedPoints(events = this.futureGames){
      let total = 0
      events.forEach(element => {
        total += (Number(element.forecasts[0].guessing_score) + this.getForecastedAssistValue(element))
      });
      return total
    },

    getEntireSeasonGoals(){
      return (Number(this.futureGames[0].forecasts[1].intermediate_values.goals_scored) + this.getTotalForecastedGoals()).toFixed(2)
    },

    getEntireSeasonAssists(){
      return (Number(this.player.extra_data.actual_assists) + this.getTotalForecastedAssist()).toFixed(2)
    },

    showModal(match){
      // this.player = match.player
      this.awayTeam = match.schedule.away_team
      this.homeTeam = match.schedule.home_team
      this.player.algorithms = match.forecasts
      this.isModalVisible = true
    },

    closeModal() {
      this.isModalVisible = false;
    },
  },

  created() {
    document.title = this.$options.title;
  },

  beforeMount(){
      this.getPlayerForecast()
  },
};
</script>
<style scoped>
    .banner{
        height: 150px;
        background-image: url("https://sportsforecaster.com/images/player-bg.jpg");
    }
    table th,
    table tbody td{
      text-align: center;
    }
</style>
